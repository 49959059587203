var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('form',{attrs:{"id":"reporting-form"}},[_c('v-card',{attrs:{"color":"brown lighten-5"}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-cash-plus")]),_vm._v("Expense Reporting "),_c('v-spacer'),(_vm.reportSubmitted)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clearForm()}}},[_vm._v("New report")]):_vm._e()],1),_c('v-card-text',[(!_vm.reportSubmitted)?_c('v-card',[_c('v-card-title',[_vm._v("Select date range")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('v-menu',{ref:"menustart",attrs:{"close-on-content-click":false,"return-value":_vm.datePickerStart,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.datePickerStart=$event},"update:return-value":function($event){_vm.datePickerStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start date for reporting period","readonly":""},model:{value:(_vm.datePickerStart),callback:function ($$v) {_vm.datePickerStart=$$v},expression:"datePickerStart"}},on))]}}],null,false,2487404191),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.datePickerStart),callback:function ($$v) {_vm.datePickerStart=$$v},expression:"datePickerStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuStart = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menustart.save(_vm.datePickerStart)}}},[_vm._v("OK")])],1)],1),_c('v-menu',{ref:"menuend",attrs:{"close-on-content-click":false,"return-value":_vm.datePickerEnd,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.datePickerEnd=$event},"update:return-value":function($event){_vm.datePickerEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End date for the reporting period","readonly":""},model:{value:(_vm.datePickerEnd),callback:function ($$v) {_vm.datePickerEnd=$$v},expression:"datePickerEnd"}},on))]}}],null,false,1892160550),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.datePickerEnd),callback:function ($$v) {_vm.datePickerEnd=$$v},expression:"datePickerEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuEnd = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuend.save(_vm.datePickerEnd)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('strong',[_vm._v("Quick select:")]),_c('br'),_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"brown white--text"},on:{"click":function($event){return _vm.lastMonthRange()}}},[_vm._v("Last Month")]),_c('br'),_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"brown white--text"},on:{"click":function($event){return _vm.thisMonthRange()}}},[_vm._v("This Month")])],1)],1),(!_vm.endAfterStart)?_c('h3',[_vm._v(" The end date is before the start date. Fix that. ")]):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":this.$v.$anyError ||
                !_vm.datePickerStart ||
                !_vm.datePickerEnd ||
                !_vm.endAfterStart},on:{"click":_vm.submit}},[_vm._v("submit")])],1)],1):_vm._e(),(_vm.reportSubmitted)?_c('ExpenseReportSummary',{staticClass:"mt-5",attrs:{"expenses":_vm.expenses,"startDate":_vm.datePickerStart,"endDate":_vm.datePickerEnd}}):_vm._e(),(_vm.reportSubmitted)?_c('ExpenseList',{staticClass:"mt-5",attrs:{"title":"Expenses for period","expenses":_vm.expenses}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }