<template>
  <v-card>
    <v-card-title>
      Invoice Report (
      <span class="date-range"
        >{{ getDate(startDate) }} to {{ getDate(endDate) }}</span
      >)
      <v-spacer />
    </v-card-title>
    <v-card-text>
      Total pre-tax:
      <p>
        <strong><MoneyFormat :value="preTaxAmount"></MoneyFormat></strong>
      </p>
      <p>
        Total Sales tax:
        <strong><MoneyFormat :value="taxAmount"></MoneyFormat></strong>
      </p>
      <h2>
        Total Expenses:
        <MoneyFormat :value="totalAmount"></MoneyFormat>
      </h2>
      <v-btn @click="getCSV()">Download CSV File of this Report</v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime, Duration } from "luxon";
import MoneyFormat from "vue-money-format";

export default {
  props: ["expenses", "startDate", "endDate"],
  components: {
    MoneyFormat,
  },
  computed: {
    taxAmount() {
      let total = 0;
      this.expenses.forEach((expense) => {
        total = total + parseInt(expense.tax);
      });
      return total / 100;
    },
    preTaxAmount() {
      let total = 0;
      this.expenses.forEach((expense) => {
        total = total + parseInt(expense.amount);
      });
      return total / 100;
    },
    totalAmount() {
      let total = 0;
      this.expenses.forEach((expense) => {
          total = total + parseInt(expense.totalAmount);
      });
      return total / 100;
    },
  },
  methods: {
    csvExportData() {
      let items = [];
      this.expenses.forEach((expense) => {
        items.push({
          Invoice_Number: expense.invoiceNumber,
          Date: this.csvDate(expense.date),
          Issued_By: expense.issuedBy,
          Description: expense.description,
          Category: expense.category,
          Amount: parseInt(expense.amount) / 100,
          Tax: parseInt(expense.tax) / 100,
          Total: parseInt(expense.totalAmount) / 100,
        });
      });
      return items;
    },
    getCSV() {
      this.$store.dispatch("downloadCSV", {
        items: this.csvExportData(),
        fileName: `Expenses_from_${this.csvDate(
          this.startDate
        )}_to_${this.csvDate(this.endDate)}.csv`,
      });
    },
    getDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL d, yyyy");
    },
    csvDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toISODate();
    },
    duration(entry) {
      let start = DateTime.fromISO(entry.startTime);
      let end = DateTime.fromISO(entry.endTime);
      let diff = end.diff(start).toObject();
      return Duration.fromObject(diff);
    },
  },
};
</script>
<style scoped>
.total-time {
  color: green;
}
.date-range {
  color: gray;
  font-weight: normal;
}

.client-name {
  color: gray;
  font-weight: normal;
  font-size: 95%;
  margin-left: 10px;
}
</style>